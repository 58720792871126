exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Arial', 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1230px;\n  --brand-color: #61dafb;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n}\n\n._1-ez5 {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.I74pk {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1230px;\n  max-width: 1230px;\n  max-width: var(--max-content-width);\n}\n", ""]);

// exports
exports.locals = {
	"root": "_1-ez5",
	"container": "I74pk"
};